.viewProfile {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 30px;
	height: 80vh;
	padding: 0.8rem;
	transition: all 0.2s ease-in-out;
	margin-bottom: 10px;
	margin-top: 10px;
}

.forMargin {
	margin-left: 20px;
	margin-bottom: 30px;
}

.profileInfoName1 {
	font-size: 32px;
	font-weight: bold;
}

.profileInfoDesc1 {
	font-size: 16px;
}

.viewProfileList {
	color: gray;
	font-size: small;
}

.profileRightBottom1 {
	margin-top: 10px;
	display: flex;
	align-items: center;
	gap: 20px;
}

.addFriendButton {
	background-color: #2e86c1;
	color: #fff;
	border: 1px #2e86c1 solid;
	border-radius: 3px;
	font-size: small;
	font-weight: bold;
	padding: 8px 8px 8px 8px;
	margin-right: 15px;
}

.visitWebsiteButton {
	background-color: #fff;
	color: #2e86c1;
	border: 1px #2e86c1 solid;
	border-radius: 3px;
	font-size: small;
	font-weight: bold;
	padding: 8px 8px 8px 8px;
    margin-right: 15px;
}

.sendFriendRequest{
    background-color: #fff;
    color: rgb(7, 116, 7);
    border: 1px rgb(7, 107, 7) solid;
    border-radius: 3px;
    font-size: small;
    font-weight: bold;
	padding: 8px 8px 8px 8px;
    margin-right: 15px;
}

.deleteFriendRequest{
    background-color: #fff;
    color: rgb(207, 7, 7);
    border: 1px rgb(209, 5, 5) solid;
    border-radius: 3px;
    font-size: small;
    font-weight: bold;
	padding: 8px 8px 8px 8px;
}

.viewProfileIcon {
	font-size: 18px;
	margin-right: 5px;
}

.friendNowIcon {
	font-size: 18px;
	margin-right: 5px;
}

.friendAddedButton {
	background-color: #2471a5;
	color: #fff;
	border: 1px #2e86c1 solid;
	border-radius: 3px;
	font-size: small;
	font-weight: bold;
	padding: 8px 8px 8px 8px;
	margin-right: 15px;
}

.coverImage {
	border-radius: 5px;
}