.sidebar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 30px;
	height: 100%;
	padding: 0.8rem;
	transition: all 0.2s ease-in-out;
	margin-bottom: 10px;
	margin-top: 10px;
}

.profile {
	display: flex;
	background-color: #fff;
	justify-content: center;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
	border-radius: 0.5rem;
}

.sidebarWrapper {
	background-color: #fff;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
	border-radius: 0.5rem;
	padding: 0.8rem;
}

.profileCover {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
	margin-bottom: 50px;
}

.coverImage {
	width: 100%;
	height: 120px;
	border-radius: 10px;
}

.userImage {
	border-radius: 50%;
	height: 80px;
	width: 80px;
	position: absolute;
	bottom: -30px;
}

.sidebarList {
	padding: 0;
	margin: 0;
	list-style: none;
}

.sidebarListItem {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.sidebarIcon {
	margin-right: 8px;
	font-size: small;
	font-weight: bold;
}

.sidebarListItemText {
	font-size: small;
	color: gray;
	font-weight: bold;
}

.sidebarButton {
	border: none;
	background-color: #fff;
	color: rgb(16, 160, 218);
	font-weight: bold;
	font-size: medium;
}

.sidebarIcon1 {
	margin-right: 8px;
	font-size: medium;
	font-weight: bold;
}

.profileInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.profileInfoName {
	font-size: medium;
	font-weight: bold;
	text-align: center;
}

.profileInfoDesc {
	font-size: small;
	color: gray;
	font-weight: bold;
}

.title {
	font-weight: bold;
	font-size: medium;
}

.profileRightBottom {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 10px;
}

.postViews {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.post {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.vl {
	border-left: 1px gray solid;
	height: 45px;
	width: 1px;
}

.postNumber {
	font-weight: bold;
	font-size: small;
	color: gray;
	text-align: center;
}

.postText {
	font-size: smaller;
	color: gray;
	text-align: center;
}

.profileRight {
	width: 100%;
}
