.postform__container {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
    position: relative;
}

.postform__imageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.postform__imageContainer img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.postform__inputContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}

.postform__inputContainer textarea {
    width: 90%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px;
    padding-left: 20px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    outline: none;
    resize: none;
}

.postform__inputContainer textarea:focus {
    outline:1px solid #999;
}

.postform__inputContainer button {
    width: 15%;
    height: 100%;
    border: none;
    border-radius: 30px;
    background-color: #001fce;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    outline: none;
    cursor: pointer;
    flex: 1;
}

.postform__inputContainer button:hover {
    background-color: #4d64eb;
}

.postform__inputContainer button:disabled {
    background-color: #d3d9ff;
    cursor: not-allowed;
}

.postform__imageUploadContainer .postform__imageUploadButton {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.postform__imageUploadContainer .postform__imageUploadButton p {
    font-weight: 500;
    margin-bottom: 0;
}

.postform__uploadedImages {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    padding-top: 10px;
}

.postform__uploadedImages img {
    border-radius: 10px;
}