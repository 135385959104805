.card {
	background-color: #212f3c;
	border-radius: 0;
	margin-top: 20px;
	color: #fff;
}

.card-title {
	font-size: medium;
	font-weight: bold;
}

.card-text {
	font-size: small;
}

.card-footer {
	font-size: medium;
}

.ttnLogo {
	height: 85px;
	margin-bottom: 10px;
}
